import React from 'react';
import { PageProps, graphql } from 'gatsby';

import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';
import { IMaker } from '../types/maker';
import { Makers } from '../components/pages/Makers';
import { IOrganizer } from '../types/organizer';

interface MakersProps {
  makers: {
    nodes: IMaker[];
  };
  organizers: {
    nodes: IOrganizer[];
  };
}

const MakersPage: React.FC<PageProps<MakersProps>> = ({ data }) => {
  return (
    <Layout padded={true}>
      <SEO title="De Makers" />
      <Makers makers={data.makers.nodes} organizers={data.organizers.nodes} />
    </Layout>
  );
};

export default MakersPage;

export const query = graphql`
  query {
    makers: allMarkdownRemark(filter: { frontmatter: { post_type: { eq: "maker" } } }) {
      nodes {
        frontmatter {
          title
          slug
          bio
          cover {
            childImageSharp {
              fluid(maxWidth: 150, maxHeight: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          twitter
          email
          linkedin
          websites
        }
        excerpt(pruneLength: 100)
      }
    }

    organizers: allMarkdownRemark(filter: { frontmatter: { post_type: { eq: "organizer" }, draft: { ne: true } } }) {
      nodes {
        frontmatter {
          title
          slug
          logo {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          link
        }
        excerpt(pruneLength: 100)
      }
    }
  }
`;
