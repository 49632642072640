import React from 'react';
import Img from 'gatsby-image';

import styles from './makers.module.scss';
import { IMaker } from '../../../types/maker';
import { IOrganizer } from '../../../types/organizer';
import clsx from 'clsx';
import { FaTwitter, FaLinkedin, FaEnvelope, FaGlobe } from 'react-icons/fa';

interface MakersDataProps {
  makers: IMaker[];
  organizers: IOrganizer[];
}

export const Makers: React.FC<MakersDataProps> = ({ makers, organizers }) => {
  return (
    <>
      <div className="container">
        <h1>De Makers</h1>
        {makers && (
          <div className={clsx('row', styles['makers'])}>
            <div className="col-xs-12">
              {/* <h3>Makers</h3> */}
              <div className="row">
                {makers.map((maker: IMaker, index: number) => (
                  <MakerItem maker={maker} key={index} />
                ))}
              </div>
            </div>
          </div>
        )}

        {organizers && (
          <div className={clsx(styles['organizers'])}>
            <hr />
            <h2 className={clsx(styles['organizers__title'])}>Organisaties</h2>
            <div className="row">
              {organizers.map((organizer: IOrganizer, index: number) => (
                <OrganizerItem organizer={organizer} key={index} />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

interface MakerItemProps {
  maker: IMaker;
}
const MakerItem: React.FC<MakerItemProps> = ({ maker }) => {
  return (
    <div className="col-xs-12 col-md-6">
      <div className={clsx('row', styles['maker-item'])}>
        {maker.frontmatter.cover && (
          <div className="col-xs-3">
            <Img className={styles['maker__img']} fluid={maker.frontmatter.cover.childImageSharp.fluid} />
          </div>
        )}
        <div className={clsx('col-xs-8', styles['maker__details'])}>
          <h3 className={styles['maker__title']}>{maker.frontmatter.title}</h3>
          {maker.frontmatter.twitter && (
            <a
              href={`https://twitter.com/${maker.frontmatter.twitter}`}
              className={styles['maker__social-link']}
              target="_blank"
            >
              <FaTwitter size={'0.875rem'} />
            </a>
          )}
          {maker.frontmatter.linkedin && (
            <a
              href={`https://linkedin.com/in/${maker.frontmatter.linkedin}`}
              className={styles['maker__social-link']}
              target="_blank"
            >
              <FaLinkedin size={'0.875rem'} />
            </a>
          )}
          {maker.frontmatter.email && (
            <a href={`mailto:${maker.frontmatter.email}`} className={styles['maker__social-link']} target="_blank">
              <FaEnvelope size={'0.875rem'} />
            </a>
          )}
          {maker.frontmatter.websites &&
            maker.frontmatter.websites.map((link: string, index: number) => (
              <a href={link} key={index} className={styles['maker__social-link']} target="_blank">
                <FaGlobe size={'0.875rem'} />
              </a>
            ))}
          <div className={styles['maker__bio']}>{maker.frontmatter.bio}</div>
        </div>
      </div>
    </div>
  );
};

interface OrganizerItemProps {
  organizer: IOrganizer;
}
const OrganizerItem: React.FC<OrganizerItemProps> = ({ organizer }) => {
  return (
    <div className={clsx('col-xs-4')}>
      <a href={organizer.frontmatter.link} target="_blank">
        {organizer.frontmatter.logo && <Img fluid={organizer.frontmatter.logo.childImageSharp.fluid} />}
        {!organizer.frontmatter.logo && <h3>{organizer.frontmatter.title}</h3>}
      </a>
    </div>
  );
};
